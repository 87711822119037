<template>
	
	<div class="sitemap-page">

		<div class="card sitemap-card" :class="[page.children ? 'has-children' : '']">

			<span v-if="length > 1 && page.ID != 4" class="sitemap-line sitemap-line-hor" :class="get_align()"></span>

			<div class="position-relative pb-4">

				<span v-if="page.children" class="sitemap-line sitemap-line-down"></span>

				<span v-if="page.ID != 4" class="sitemap-line sitemap-line-up"></span>

				<div v-if="!hide_edit || (page.ID != 4 && hide_edit)" class="sitemap-card-toolbar">
					<div class="btn-group dropdown">
						<button 
						class="btn"  
						type="button" 
						:id="'sitemap-page-' + page.ID + '-dropdown'" 
						data-bs-toggle="dropdown" 
						aria-expanded="false"
						>
							<span class="material-icons-outlined">more_vert</span>
						</button>
						<ul class="dropdown-menu" :aria-labelledby="'sitemap-page-' + page.ID + '-dropdown'">

							<!-- <li>
								<router-link :to="{ name: 'site.planning.page', params: { page_id: page.ID } }"
								>
									<span class="material-icons-outlined me-2">edit</span>
									Content
								</router-link>
							</li> -->

							<li v-if="page.ID != 4 && !move.id">
								<button 
								class="sitemap-card-move"
								v-on:click="$emit('update:moveId', page.ID)"
								>
									<span class="material-icons-outlined me-2">drag_indicator</span>
									Move
								</button>
							</li>

							<li v-if="!hide_edit">
								<router-link :to="{ name: 'site.planning.page', params: { page_id: page.ID } }">
									<span class="material-icons-outlined me-2">edit</span>
									Edit
								</router-link>
							</li>

							<li v-if="page.ID != 4 && !move.id">
								<button 
								data-bs-toggle="modal" 
								data-bs-target="#delete-page-modal"
								class="sitemap-card-delete"
								v-on:click="$emit('update:parentId', { page: page, is_new: false })"
								>
									<span class="material-icons-outlined me-2">delete_forever</span>
									Delete
								</button>
							</li>

						</ul>
					</div>
				</div>

				<div class="card-body p-2 d-flex align-items-center justify-content-center" style="min-height: 75px;">

					<p class="mb-0 pt-3 fw-bold">{{ trim( page.post_title ) }}</p>

				</div>

				<button 
				data-bs-toggle="modal" 
				data-bs-target="#add-page-modal"
				class="sitemap-card-add bg-success text-primary"
				v-on:click="$emit('update:parentId', { page: page, is_new: true })"
				v-if="!move.id"
				>
					<span class="material-icons-outlined">add</span>
				</button>

				<button 
				class="sitemap-card-add-sm bg-white text-primary border-primary"
				style="border: 1px solid #fff" 
				v-on:click="$emit('update:moveParentId', {parent_id: page.ID, direction:'bottom'})"
				v-if="move.id && page.ID != move.id && ( !loading || ( page.ID == move.id ) )"
				>
					<span v-if="loading" class="spinner-border spinner-border-sm" role="status">
						<span class="visually-hidden">Loading...</span>
					</span>
					<span v-else class="material-icons-outlined">add</span>
				</button>

				<button 
				class="sitemap-card-add-sm sitemap-card-add-left bg-white text-primary border-primary"
				style="border: 1px solid #fff" 
				v-on:click="$emit('update:moveParentId', {parent_id: page.ID, direction:'left'})"
				v-if="move.id && page.ID != move.id && page.ID != 4 && ( !loading || ( page.ID == move.id ) )"
				>
					<span v-if="loading" class="spinner-border spinner-border-sm" role="status">
						<span class="visually-hidden">Loading...</span>
					</span>
					<span v-else class="material-icons-outlined">add</span>
				</button>

				<button 
				class="sitemap-card-add-sm sitemap-card-add-right bg-white text-primary border-primary"
				style="border: 1px solid #fff" 
				v-on:click="$emit('update:moveParentId', {parent_id: page.ID, direction:'right'})"
				v-if="move.id && page.ID != move.id && page.ID != 4 && ( !loading || ( page.ID == move.id ) )"
				>
					<span v-if="loading" class="spinner-border spinner-border-sm" role="status">
						<span class="visually-hidden">Loading...</span>
					</span>
					<span v-else class="material-icons-outlined">add</span>
				</button>

				<button 
				class="w-100 h-100 position-absolute top-0 start-0 bg-white bg-opacity-75 border-primary border-radius text-primary"
				style="border: 1px solid #fff" 
				v-on:click="$emit('update:moveId', null)"
				v-if="move.id && page.ID == move.id"
				>
					<span v-if="loading" class="spinner-border spinner-border-sm me-2" role="status">
						<span class="visually-hidden">Loading...</span>
					</span>
					<span v-else class="btn btn-primary">Cancel</span>
				</button>

			</div>

		</div>

		<div v-if="page.children" class="sitemap-children d-flex">

			<div v-for="(child_page, child_key) in page.children" :key="'sitemap-page-' + child_page.ID">
				<sitemap_page 
				:hide_edit="hide_edit"
				:page="child_page" 
				:move="move"
				:loading="loading"
				:length="page.children.length"
				:index="child_key" 
				v-on:update:parentId="update_parent_id" 
				v-on:update:moveId="update_move_id" 
				v-on:update:moveParentId="update_move_parent_id" 
				/>
			</div>

		</div>

	</div>

</template>

<script>
import sitemap_page from '@/components/sitemap_page'

export default {
	name: 'components.sitemap_page',
	props: {
		page: Object,
		move: Object,
		loading: Boolean,
		length: Number,
		index: Number,
		hide_edit: Boolean
	},
	emits: [
		'update:parentId',
		'update:moveId',
		'update:moveParentId',
	],
	components: {
		sitemap_page
	},
	data() {
		return {
			
		}
	},
	mounted()
	{
		
	},
	methods: {

		update_parent_id( e )
		{
			this.$emit('update:parentId', e)
		},

		update_model_value( e )
		{
			this.$emit('update:modelValue', e)
		},

		update_move_id( e )
		{
			this.$emit('update:moveId', e)
		},

		update_move_parent_id( e )
		{
			this.$emit('update:moveParentId', e)
		},

		trim( string )
		{
			var length = 20;

			return string.length > length ? string.substring(0, length - 3) + "..." : string;
		},

		get_align()
		{
			if ( this.index == 0  ) {
				return ''
			}
			if ( this.index == ( this.length - 1 )  ) {
				return 'end'
			}
			return 'center'
		}
	}
}
</script>

<style>
.sitemap-line {
	background: #ddd;
	display: block;
	position: absolute;
}
.sitemap-line-up {
	width: 1px;
	height: 35px;
	top: -36px;
	left: 50%;
}
.sitemap-line-down {
	width: 1px;
	height: 35px;
	bottom: -36px;
	left: 50%;
}
.sitemap-line-hor {
	width: 100%;
	height: 1px;
	top: 35px;
	left: 50%;
}
.sitemap-line-hor.center {
	left:0;
}
.sitemap-line-hor.end {
	left:auto;
	right: 50%;
}
.sitemap-page {
	margin: 0;
	padding: 70px 20px;
	position: relative;
	top: 20px;
	opacity: 0;
	transition: all 0.5s ease-in-out;
}
.sitemap-pages > .sitemap-page {
	padding: 40px 20px;
}
.sitemap-loaded .sitemap-page {
	top: 0;
	opacity: 1;
}
.sitemap-card {
	width: 200px;
	text-align: center;
	position: static;
	margin: 0 auto;
	z-index: 1;
	border-radius: 3px 0 0 3px;
}
.sitemap-card input {
	text-align: center;
}
.sitemap-card-add {
	width: 40px;
	height: 40px;
	border: none;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	position: absolute;
	bottom: -20px;
	left: 50%;
	z-index: 1;
	transform: translate( -50%, 0 );
}
.sitemap-card-add-sm {
	width: 25px;
	height: 25px;
	border: none;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	position: absolute;
	bottom: -12.5px;
	left: 50%;
	z-index: 1;
	transform: translate( -50%, 0 );
}
.sitemap-card-add-left {
	top: 50%;
	left: -12.5px;
	transform: translate( 0, -50% );
}
.sitemap-card-add-right {
	top: 50%;
	left: auto;
	right: -12.5px;
	transform: translate( 0, -50% );
}
.sitemap-card-add::active,
.sitemap-card-add::focus {
	border: none;
	outline: none;
}
.sitemap-card-toolbar {
	width: 40px;
	position: absolute;
	top: -1px;
	right: 0px;
}
.sitemap-card-toolbar button,
.sitemap-card-toolbar a {
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	background: none;
	border: none;
	color: var( --bs-primary );
	text-decoration: none;
	padding: 0 1rem;
}
.sitemap-card-toolbar .btn {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: none;
	border: none;
	color: var( --bs-primary );
}
</style>
