<template>

	<div v-if="zoom" class="d-flex align-items-center position-absolute top-0 end-0 m-4" style="z-index:10;">
		<span class="small text-end me-2" style="width: 100px;">{{ zoom }}%</span>
		<div class="btn-group">
			<button v-on:click="update_zoom( -10 )" class="btn btn-outline-primary d-flex align-items-center justify-content-center" type="button">
				<span class="material-icons-outlined">zoom_out</span>
			</button>
			<button v-on:click="update_zoom( 10 )" class="btn btn-outline-primary d-flex align-items-center justify-content-center" type="button">
				<span class="material-icons-outlined">zoom_in</span>
			</button>
		</div>
	</div>

	<div class="sitemap-container w-100 h-100 overflow-auto position-relative">
		<div class="sitemap w-100 h-100 p-4 position-relative">

			<div id="sitemap-draggable" class="d-flex align-items-center justify-content-center" style="width: fit-content; height: fit-content; min-width: 100%;" :style="sitemap_transform">
				
				<div v-for="( wp_page ) in pages" :key="'wp-page-' + wp_page.ID" class="sitemap-pages">
					<sitemap_page 
					:hide_edit="hide_edit"
					:page="wp_page" 
					:move="move"
					:loading="loading"
					v-on:update:parentId="update_parent_id" 
					v-on:update:moveId="update_move_id" 
					v-on:update:moveParentId="update_move_parent_id" 
					/>
				</div>

			</div>

		</div>

		<div v-if="pages" class="modal fade" :id="'add-page-modal'" tabindex="-1" :aria-labelledby="'add-page-modal-label'" aria-hidden="true">

			<div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">

				<div class="modal-content">

					<form @submit.prevent="store_wp_pages()">

						<div class="modal-header">
							<h5 class="modal-title" :id="'add-page-modal-label'">Add new page</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">

							<form_control
								label="Page Title"
								type="text"
								placeholder="eg: My New Page"
								name="title"
								group_class="mb-0"
								:error="error"
								v-model="new_page.title"
							/>
							
						</div>
						<div class="modal-footer d-flex">
							<div class="w-100">
								<btn_submit 
									:label="'Add Page'" 
									icon="add_circle_outline"  
									:icon_after="true" 
									:loading="loading" 
								/>
							</div>
						</div>

					</form>
				</div>

			</div>

		</div>

		<div v-if="pages" class="modal fade" :id="'delete-page-modal'" tabindex="-1" :aria-labelledby="'delete-page-modal-label'" aria-hidden="true">

			<div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">

				<div class="modal-content">

					<form @submit.prevent="delete_wp_pages()">

						<div class="modal-header">
							<h5 class="modal-title" :id="'delete-page-modal-label'">Delete {{ new_page.title }}</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">

							<p class="mb-0">This action cannot be undone. Are you sure you wish to continue?</p>
							
						</div>
						<div class="modal-footer d-flex">
							<div class="w-100">
								<btn_submit 
									:label="'Yes, delete page'" 
									icon="check"  
									:icon_after="true" 
									:loading="loading" 
								/>
							</div>
						</div>

					</form>
				</div>

			</div>

		</div>

		<div v-if="pages" class="modal fade" :id="'duplicate-page-modal'" tabindex="-1" :aria-labelledby="'duplicate-page-modal-label'" aria-hidden="true">

			<div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">

				<div class="modal-content">

					<form @submit.prevent="duplicate_wp_pages()">

						<div class="modal-header">
							<h5 class="modal-title" :id="'duplicate-page-modal-label'">Duplicate {{ new_page.title }}</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">

							<p class="mb-0">This action will create a copy of the selected page along with all of it's wireframe content.</p>
							
						</div>
						<div class="modal-footer d-flex">
							<div class="w-100">
								<btn_submit 
									:label="'Yes, duplicate page'" 
									icon="check"  
									:icon_after="true" 
									:loading="loading" 
								/>
							</div>
						</div>

					</form>
				</div>

			</div>

		</div>

	</div>

</template>

<script>
import sitemap_page from '@/components/sitemap_page'
import form_control from '@/components/form_control'
import btn_submit from '@/components/btn_submit'
import page_service from '@/services/page_service'

export default {
	name: 'components.sitemap',
	props: {
		length: Number,
		index: Number,
		hide_edit: Boolean
	},
	emits: [
		'update:modelValue',
		'update:parentId',
		'update:moveId',
		'update:moveParentId',
	],
	components: {
		sitemap_page,
		form_control,
		btn_submit
	},
	data() {
		return {
			loading: false,
			error: {},
			pages: [],
			move: {
				id: null,
				parent_id: null
			},
			new_page: {
				title: null,
				parent_id: null,
				original_content: '',
				features:[]
			},
			zoom: 100,
		}
	},
	computed: {
		sitemap_transform()
		{
			var scale = this.zoom / 100  

			return 'transition: transform 0.25s ease-in-out; transform: scale(' + scale + ');'
		},
	},
	mounted()
	{
		this.get_pages()

		this.sitemap_default( 1 )
	},
	methods: {

		reset_error()
		{
			this.error = {}
		},

		update_parent_id( e )
		{
			this.new_page.parent_id = e.page.ID
			this.generated_content = null
			this.ai_description = null
			if ( !e.is_new ) {
				this.new_page.title = e.page.post_title
				this.new_page.original_content = e.page.original_content
				this.new_page.features = ( e.page.features ? e.page.features : [] )
			} else {
				this.new_page.title = null
				this.new_page.original_content = null
				this.new_page.features = []
			}
		},

		update_move_id( e )
		{
			this.move.id = e
		},

		update_move_parent_id( e )
		{
			this.move.parent_id = e.parent_id
			this.move.direction = e.direction

			this.update_wp_page()
		},

		sitemap_default( count )
		{
			var $ = window.$

			if ( $( '#sitemap-draggable' ).length && $( '#sitemap-draggable' ).width() ) {

				var $sitemap = $('.sitemap-container');
				
				var container_width = $sitemap.width();

				var content_width = $( '#sitemap-draggable' ).width();

				var scrollLeft = (content_width - container_width) / 2;
				
				if ( scrollLeft > 0 || count > 2 ) {
					$sitemap.scrollLeft(scrollLeft);
				
					$sitemap.addClass( 'sitemap-loaded' );
				} else {
					setTimeout( () => {
						this.sitemap_default( ( count+1) )
					}, 500)
				}
				

			} else {
				setTimeout( () => {
					this.sitemap_default( ( count+1) )
				}, 500)
			}
		},

		async get_pages()
		{			
			this.reset_error()

			this.loading = true

			await page_service.get_wordpress( this.$route.params.workspace_id, this.$route.params.site_id ).then(( response ) => {

				this.pages = response.data

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		async store_wp_pages()
		{			
			this.reset_error()

			this.loading = true

			await page_service.store_wordpress( this.$route.params.workspace_id, this.$route.params.site_id, this.new_page ).then(( response ) => {

				this.pages = response.data

				this.new_page = {
					title: null,
					parent_id: null,
					original_content: '',
					features: []
				}

				var $ = window.$

				$( '#add-page-modal' ).modal( 'hide' )

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		async update_wp_page()
		{			
			this.reset_error()

			this.loading = true

			await page_service.update_wordpress( this.$route.params.workspace_id, this.$route.params.site_id, this.move ).then(( response ) => {

				this.pages = response.data

				this.move = {
					id: null,
					parent_id: null
				}

				var $ = window.$

				$( '#add-page-modal' ).modal( 'hide' )

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		async duplicate_wp_pages()
		{			
			this.reset_error()

			this.loading = true

			await page_service.duplicate_wordpress( this.$route.params.workspace_id, this.$route.params.site_id, this.new_page.parent_id ).then(( response ) => {

				this.pages = response.data

				this.new_page = {
					title: null,
					parent_id: null,
					original_content: '',
					features: []
				}

				var $ = window.$

				$( '#duplicate-page-modal' ).modal( 'hide' )

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		async delete_wp_pages()
		{			
			this.reset_error()

			this.loading = true

			await page_service.delete_wordpress( this.$route.params.workspace_id, this.$route.params.site_id, this.new_page.parent_id ).then(( response ) => {

				this.pages = response.data

				this.new_page = {
					title: null,
					parent_id: null,
					original_content: '',
					features: []
				}

				var $ = window.$

				$( '#delete-page-modal' ).modal( 'hide' )

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		update_zoom( zoom )
		{
			var new_zoom = this.zoom

			if ( zoom > 0 ) {
				new_zoom += zoom
			} else {
				new_zoom += zoom
			}
			if ( new_zoom > 0 && new_zoom < 101 ) {
				this.zoom = new_zoom
			}
		},
	}
}
</script>

<style>
#sitemap-draggable {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}
.sitemap-line {
	background: #ddd;
	display: block;
	position: absolute;
}
.sitemap-line-up {
	width: 1px;
	height: 35px;
	top: -36px;
	left: 50%;
}
.sitemap-line-down {
	width: 1px;
	height: 35px;
	bottom: -36px;
	left: 50%;
}
.sitemap-line-hor {
	width: 100%;
	height: 1px;
	top: 35px;
	left: 50%;
}
.sitemap-line-hor.center {
	left:0;
}
.sitemap-line-hor.end {
	left:auto;
	right: 50%;
}
.sitemap-page {
	margin: 0;
	padding: 70px 20px;
	position: relative;
	top: 20px;
	opacity: 0;
	transition: all 0.5s ease-in-out;
}
.sitemap-pages > .sitemap-page {
	padding: 40px 20px;
}
.sitemap-loaded .sitemap-page {
	top: 0;
	opacity: 1;
}
.sitemap-card {
	width: 200px;
	text-align: center;
	position: static;
	margin: 0 auto;
	z-index: 1;
	border-radius: 3px 0 0 3px;
}
.sitemap-card input {
	text-align: center;
}
.sitemap-card-add {
	width: 40px;
	height: 40px;
	border: none;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	position: absolute;
	bottom: -20px;
	left: 50%;
	z-index: 1;
	transform: translate( -50%, 0 );
}
.sitemap-card-add-sm {
	width: 25px;
	height: 25px;
	border: none;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	position: absolute;
	bottom: -12.5px;
	left: 50%;
	z-index: 1;
	transform: translate( -50%, 0 );
}
.sitemap-card-add-left {
	top: 50%;
	left: -12.5px;
	transform: translate( 0, -50% );
}
.sitemap-card-add-right {
	top: 50%;
	left: auto;
	right: -12.5px;
	transform: translate( 0, -50% );
}
.sitemap-card-add::active,
.sitemap-card-add::focus {
	border: none;
	outline: none;
}
.sitemap-card-toolbar {
	width: 40px;
	position: absolute;
	top: -1px;
	right: 0px;
}
.sitemap-card-toolbar button {
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	background: none;
	border: none;
	color: var( --bs-primary );
}
.sitemap-card-toolbar .btn {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: none;
	border: none;
	color: var( --bs-primary );
}
</style>
